<template>
  <div v-if="formState && userData">
    <sdCards>
      <template v-slot:title>
        <div class="settings-card">
          <div class="setting-card-title">
            <sdHeading as="h4">{{ i18n.t('global.2FacChange') }}</sdHeading>
            <span>{{ i18n.t('profileView.twoFacSubtitle') }}</span>
          </div>
          <div class="settings-card__button" v-if="formState.otp_required_for_login">
            <sdButton type="primary" @click="openCode">{{ i18n.t('global.openTwoFacAgain') }} </sdButton>
          </div>
        </div>
      </template>
      <div class="notification-box-single">
        <BasicFormWrapper>
          <a-form class="notification-body">
            <a-form-item>
              <div :style="listStyle">
                <p style="margin: 0">
                  {{ i18n.t('global.2FacSubTitle') }}
                </p>
                <a-switch
                  @change="handleFinish"
                  v-model:checked="formState.otp_required_for_login"
                  style="margin-left: 20px"
                />
              </div>
            </a-form-item>
          </a-form>
        </BasicFormWrapper>
      </div>
    </sdCards>
    <sdModal
      centered
      type="primary"
      :title="formState.otp_required_for_login ? i18n.t('global.createSecondAuth') : i18n.t('global.deletedSecondAuth')"
      :visible="visibleCheckCode"
      :onCancel="changeModalCode"
      :onOk="handleOk"
      :width="innerWidth > 500 ? 430 : 300"
    >
      <div>
        <div v-if="formState.otp_required_for_login">
          <div v-if="twoCode">
            <div class="qr-code__wrapper">
              <QrcodeVue
                :value="`otpauth://totp/MTHUB-${twoCode.email}?secret=${twoCode.otp}&issuer=MTHUB`"
                :size="250"
                level="H"
              />
            </div>
            <p class="qr-code__description">{{ i18n.t('global.2FacSupportSecond') }}</p>
            <p class="qr-code__second-code">
              {{ i18n.t('global.2FacSupportFirst', 1) }} <span>"{{ twoCode.otp }}"</span>
              {{ i18n.t('global.2FacSupportFirst', 2) }}
            </p>
            <div class="qr-code__wrapper">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US"
              >
                <sdButton type="primary" outlined> Google Play </sdButton>
              </a>
              <a target="_blank" href="https://apps.apple.com/ru/app/google-authenticator/id388497605">
                <sdButton type="primary" outlined> App Store </sdButton>
              </a>
            </div>
            <div v-if="!openCodeAgain">
              <p>{{ i18n.t('global.inputCode') }}</p>
              <a-input
                class="qr-code__input"
                v-model:value="checkCode.otp_attempt"
                @keydown.enter="sendCode"
                size="large"
              />
            </div>
            <div class="qr-code__wrapper" v-if="!openCodeAgain">
              <sdButton type="danger" outlined @click="changeModalCode">
                {{ i18n.t('transactions.cancel') }}
              </sdButton>
              <sdButton type="success" outlined @click="sendCode" :disabled="isLoading">
                {{ i18n.t('transactions.approve') }}
              </sdButton>
            </div>
          </div>
          <div class="loading-data" v-else>
            <a-spin size="large"></a-spin>
          </div>
        </div>
        <div v-else>
          <div>
            <p>{{ i18n.t('global.inputCode') }}</p>
            <a-input
              class="qr-code__input"
              @keydown.enter="deleteTwoFac"
              v-model:value="checkCode.otp_attempt"
              size="large"
            />
          </div>
          <div class="qr-code__wrapper">
            <sdButton type="danger" outlined @click="changeModalCode">
              {{ i18n.t('transactions.cancel') }}
            </sdButton>
            <sdButton type="success" outlined @click="deleteTwoFac" :disabled="isLoading">
              {{ i18n.t('transactions.approve') }}
            </sdButton>
          </div>
        </div>
      </div>
    </sdModal>
    <sdModal
      centered
      type="primary"
      :title="
        formState.otp_required_for_login ? i18n.t('global.createSecondAuth', 2) : i18n.t('global.deletedSecondAuth')
      "
      :visible="visibleQR"
      :onCancel="changeModal"
      :width="innerWidth > 500 ? 430 : 300"
      v-if="visibleQR"
    >
      <div>
        <div v-if="formState.otp_required_for_login">
          <p class="qr-code__second-code">
            {{ i18n.t('global.2FacSupportFirst', 1) }} <span>"{{ twoCode.otp }}"</span>
            {{ i18n.t('global.2FacSupportFirst', 2) }}
          </p>
        </div>
        <div v-else>
          <p>{{ i18n.t('global.deletedSecondAuthSuccess') }}</p>
        </div>
      </div>
    </sdModal>
  </div>
</template>
<script>
/* eslint-disable */

import { ChangePasswordWrapper } from './style';
import { BasicFormWrapper } from '../../../styled';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import QrcodeVue from 'qrcode.vue';

const Global = defineComponent({
  name: 'Global',
  components: { ChangePasswordWrapper, QrcodeVue, BasicFormWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const i18n = useI18n();

    const twoCode = computed(() => state.profile.twoCode);
    const twoFacKey = computed(() => state.profile.twoFactorKey);
    const isLoading = computed(() => state.profile.isLoading);
    const formState = computed(() => state.profile.userSettings);
    const userData = computed(() => state.profile.data);
    const qrCode = computed(() => state.profile.twoFactorKey);
    const innerWidth = computed(() => window.innerWidth);
    const error = computed(() => state.profile.error);

    const visibleQR = ref(false);
    const visibleCheckCode = ref(false);
    const checkCode = ref({ otp_attempt: '' });
    const openCodeAgain = ref(false);

    const changeModal = () => {
      visibleQR.value = !visibleQR.value;
    };

    const changeModalCode = data => {
      visibleCheckCode.value = !visibleCheckCode.value;
      if (data && !openCodeAgain.value) {
        formState.value.otp_required_for_login = !formState.value.otp_required_for_login;
      }
      if (openCodeAgain.value) {
        openCodeAgain.value = false;
      }
    };
    const sendCode = () => {
			if(isLoading.value){
				return
			}
      dispatch('enableTwoFactory', checkCode.value).then(() => {
        if (!error.value) {
          changeModalCode(false);
          changeModal();
        } else {
          message.error(error.value.error);
        }
      });
    };
    const deleteTwoFac = () => {
						if(isLoading.value){
				return
			}
      dispatch('disableTwoFactory', checkCode.value).then(() => {
        if (!error.value) {
          changeModalCode(false);
          changeModal();
        } else {
          message.error(error.value.error);
        }
      });
    };
    const handleFinish = data => {
      changeModalCode();
      if (data) {
        dispatch('getCodeTwoFactory');
      }
    };
    const openCode = () => {
      dispatch('getCodeTwoFactoryAgain').then(() => {
        changeModalCode(false);
        openCodeAgain.value = true;
      });
    };

    const listStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      lineHeight: '2rem',
    };
    onMounted(() => {
      dispatch('axiosGetSettings');
    });
    return {
      isLoading,
      twoFacKey,
      i18n,
      listStyle,
      formState,
      userData,
      visibleQR,
      qrCode,
      innerWidth,
      twoCode,
      checkCode,
      visibleCheckCode,
      openCodeAgain,
      handleFinish,
      changeModal,
      changeModalCode,
      sendCode,
      deleteTwoFac,
      openCode,
    };
  },
});

export default Global;
</script>
<style lang="scss" scoped>
.qr-code {
  &__wrapper {
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    &:nth-child(1) {
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
  &__input {
    width: 100%;
  }
  &__description {
    font-weight: 600;
  }
  &__second-code {
    span {
      font-weight: 600;
    }
  }
}
.settings-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
