<template>
  <div v-if="subscriptionData">
    <sdCards>
      <template v-slot:title>
        <div class="settings-card">
          <div class="setting-card-title">
            <sdHeading as="h4">{{ i18n.t('profileView.subscription.title') }}</sdHeading>
            <span>{{ i18n.t('profileView.subscription.subtitle') }}</span>
          </div>
        </div>
      </template>
      <template v-slot:button v-if="subscriptionData.kind != 'vip'">
        <sdButton size="small" class="renew-subscription--button" type="success" @click="openRenewModal" outlined>
          {{ i18n.t('profileView.subscription.renew') }}
        </sdButton>
      </template>
      <div class="setting-subscription">
        <div class="setting-subscription__info">
          <div class="setting-subscription__title">
            {{ i18n.t('profileView.subscription.title') }}
          </div>
          <div class="setting-subscription__value">
            {{ i18n.t(`profileView.subscription.kinds.${subscriptionData.kind}`) }}
          </div>
          <div class="setting-subscription__title">
            {{ i18n.t('profileView.subscription.expiration') }}
          </div>
          <div class="setting-subscription__value">
            {{
              subscriptionData.kind == 'vip'
                ? i18n.t('profileView.subscription.indefinitely')
                : subscriptionData.expiration_at
            }}
          </div>
        </div>

        <div class="setting--subscription__description">
          <div class="setting-subscription__title">
            {{ i18n.t('profileView.subscription.description-title') }}
          </div>
          <div v-html="i18n.t('profileView.subscription.description-html')"></div>
        </div>
      </div>
    </sdCards>
  </div>
  <RenewSubscriptionModal v-model:data="renewData" v-on:dataSended="updateData" />
</template>
<script>
/* eslint-disable */

import {computed, defineComponent, defineAsyncComponent, ref} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
// import { message } from 'ant-design-vue';

const RenewSubscriptionModal = defineAsyncComponent(() => import('@/components/modals/RenewSubscriptionModal'));

const Global = defineComponent({
  name: 'Global',
  components: { RenewSubscriptionModal },
  setup() {
    const { state, dispatch } = useStore();
    const i18n = useI18n();
    const subscriptionData = computed(() => state.profile.subscription);

    const renewData = ref({ visible: false });

    const toggleModal = () => {
      renewData.value.visible = !renewData.value.visible;
    };

    const openRenewModal = () => {
      toggleModal();
      // dispatch('axiosSecurityCloseAllSessions');
      // dispatch('auth/logout');
    };

    const updateData = () => {
      dispatch('axiosProfileGetData');
    }

    return {
      i18n,
      subscriptionData,
      openRenewModal,
      renewData,
      updateData
    };
  },
});

export default Global;
</script>
<style lang="scss" scoped>
.setting-subscription {
  &__info {
    padding-bottom: 26px;
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
  }
  &__value {
    font-size: 14px;
    padding-bottom: 20px;
  }
  &__description {}
}
</style>
